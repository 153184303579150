




































































































































































import { ElForm } from 'element-ui/types/form';
import { Component, Vue } from 'vue-property-decorator';

interface FormData {
  topic: string;
  standardType: string;
  sort: number | string | undefined;
  topicType: string;
  more: string;
  [index: string]: any;
}
@Component({
  name: 'EvaluatingQuestion',
})
export default class EvaluatingQuestion extends Vue {
  public currentPage4 = 4;
  public ceshi1 = '123';
  public formLabelWidth = '120px';
  public test = '';
  public form = {
    topic: '',
    category: '',
  };
  public rules = {
    sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
    standardType: [
      { required: true, message: '请选择疾病类型', trigger: 'blur' },
    ],
    topic: [{ required: true, message: '请填写题目', trigger: 'change' }],
    topicType: [
      {
        type: 'date',
        required: true,
        message: '请选择题目类型',
        trigger: 'blur',
      },
    ],
    more: [{}],
  };
  public formData: FormData = {
    sort: '',
    standardType: '',
    more: '',
    topic: '',
    topicType: '',
  };
  public dialogFormVisible = false;
  public onSubmit() {
    // console.log(1);
    console.log(this.form);
  }
  public handlerSubmit(formData: string) {
    (this.$refs[formData] as ElForm).validate((valid: any) => {
      if (valid) {
        alert('submit!');
      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }
  public hanlderCancel(formData: string) {
    this.dialogFormVisible = false;
    (this.$refs[formData] as ElForm).resetFields();
  }
  public tableData = [
    {
      topic: '亲属中明确诊断疾病',
      category: '多选',
      illness: '恶性肿瘤',
      sort: 1,
    },
    {
      topic: '亲属中明确诊断疾病',
      category: '多选',
      illness: '恶性肿瘤',
      sort: 1,
    },
    {
      topic: '亲属中明确诊断疾病',
      category: '多选',
      illness: '恶性肿瘤',
      sort: 1,
    },
    {
      topic: '亲属中明确诊断疾病',
      category: '多选',
      illness: '恶性肿瘤',
      sort: 1,
    },
    {
      topic: '亲属中明确诊断疾病',
      category: '多选',
      illness: '恶性肿瘤',
      sort: 1,
    },
    {
      topic: '亲属中明确诊断疾病',
      category: '多选',
      illness: '恶性肿瘤',
      sort: 1,
    },
    {
      topic: '亲属中明确诊断疾病',
      category: '多选',
      illness: '恶性肿瘤',
      sort: 1,
    },
    {
      topic: '亲属中明确诊断疾病',
      category: '多选',
      illness: '恶性肿瘤',
      sort: 1,
    },
    {
      topic: '亲属中明确诊断疾病',
      category: '多选',
      illness: '恶性肿瘤',
      sort: 1,
    },
    {
      topic: '亲属中明确诊断疾病',
      category: '多选',
      illness: '恶性肿瘤',
      sort: 1,
    },
    {
      topic: '亲属中明确诊断疾病',
      category: '多选',
      illness: '恶性肿瘤',
      sort: 1,
    },
    {
      topic: '亲属中明确诊断疾病',
      category: '多选',
      illness: '恶性肿瘤',
      sort: 1,
    },
    {
      topic: '亲属中明确诊断疾病',
      category: '多选',
      illness: '恶性肿瘤',
      sort: 1,
    },
  ];
  public editRow(): void {
    this.dialogFormVisible = true;
  }
  public deleteRow(index: number, rows: any): void {
    rows.splice(index, 1);
  }
  public handleSizeChange(val: any): void {
    console.log(`每页 ${val} 条`);
  }
  public handleCurrentChange(val: any): void {
    console.log(`当前页: ${val}`);
  }
}
